<template>
    <div class="ProjectDetail flex-col ali-center">
        <div class="commonTitle">{{ listData.title }}</div>
<!--        <img class="detailImg" v-for="item in listData.contentImgList" :key="item" :src="'http://101.35.0.207:8888/'+item" alt="图片" srcset="">-->
        <div class="detailImgVideoDiv" v-if="listData.videoUrl != null && listData.videoUrl != ''">
            <img :src="'http://101.35.0.207:8888/'+listData.frameUrl" class="detailImgVideo" alt="" v-if="listData.videoUrl != null && listData.videoUrl != ''"/>
            <img srcset="../assets/icon_play01.png" @click="play(listData)" class="play"/>
        </div>
        <img v-else class="detailImg" v-for="item in  listData.contentImgList" :key="item" :src="'http://101.35.0.207:8888/'+item" alt="图片" srcset="">
       <div class="text">{{listData.content}}</div>

        <el-button @click="back()" size="medium "  style="margin-bottom: 3%;margin-top: 4%;margin-left: 56%;width: 6%;height: 18%;text-align: center;align-items: center;"> 返回</el-button>
        <!-- <img src="../assets/back.png" @click="back()" class="backbtn"> -->

        <el-dialog
                class="dialog"
                :visible.sync="videoDialog"
                top="14vh"
                @open="openFun"
                width="62%"
                :before-close="videoClose"
                :append-to-body="true"
                :showClose="false">

            <div id="player">
                <video-player
                        ref="videoPlayer1"
                        class="video-player vjs-big-play-centered"
                        :playsinline="false"
                        :options="playerOptions"
                        @ready="onPlayerReady"
                        @play="onPlayerPlay($event)"
                        @pause="onPlayerPause($event)"
                        @ended="onPlayerEnd($event)"
                />
            </div>

        </el-dialog>
    </div>
</template>

<script>
	import {getGwTwoList} from "@/api/officialCases/officialCases.js";
    import { videoPlayer } from 'vue-video-player'
    import 'video.js/dist/video-js.css'
    export default {
        name: 'ProjectDetail',
        components: {videoPlayer},
        data() {
            return {
				listData:[],
                videoDialog:false,
                videoUrl:'',
                currentPlayer: null,
                playerOptions: {
                    playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                    autoplay: true, // 如果为true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: true, // 是否视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4", // 类型
                        src: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4' // url地址
                        // src: this.videoUrl  // url地址
                    }],
                    // poster: props.cover, // 封面地址
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true, // 当前时间和持续时间的分隔符
                        durationDisplay: true, // 显示持续时间
                        remainingTimeDisplay: true, // 是否显示剩余时间功能
                        fullscreenToggle: true // 是否显示全屏按钮
                    }
                }
            }
        },
        mounted() {
          getGwTwoList(this.$route.query.detail).then(response=>{
			  this.listData=response.data
		  })
          sessionStorage.setItem("msgInfo",true)
        },
        computed: {
            player() {
                return this.$refs.videoPlayer1 ? this.$refs.videoPlayer1.player : null;
            },
        },
        methods: {
            // 准备好了
            onPlayerReady(v) {
                console.log("准备好了")
                console.log(v)
                this.currentPlayer = v;
                this.onPlayerPlay(v)
            },
            // 播放了
            onPlayerPlay(v) {
                console.log("播放了")
                console.log(v)
            },
            // 暂停了
            onPlayerPause(v) {
                console.log("暂停了")
                console.log(v)
            },
            // 播放结束了
            onPlayerEnd(v) {
                console.log("播放结束了")
                console.log(v)
            },
            openFun(){
                if (this.currentPlayer){
                    //重置视频播放进度
                    this.currentPlayer.src(this.videoUrl)
                    //开始播放
                    this.currentPlayer.play();
                }
            },
            videoClose(){
                this.videoDialog = false
                console.log("停止")
                console.log(this.player)
                if (this.currentPlayer){
                    this.currentPlayer.pause();
                }
            },
            back() {
                this.$router.go(-1)
                // this.$router.back()
            },
            play(item) {
                console.log("播放视频", item)
                this.videoUrl = "http://101.35.0.207:8888/"+item.videoUrl
                this.playerOptions.sources[0].src = "http://101.35.0.207:8888/" + item.videoUrl
                this.videoDialog = true
            },
        },
    }
</script>

<style lang="stylus" scoped>
.text{
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    max-width 1200px;
    text-align:left;
    line-height:200%;
    text-indent:30px
}
.ProjectDetail {
    padding-top 80px
}

.dialog{
    >>> .el-dialog__header {
        background-color black
        padding: 0 0 0;
    }

    >>> .el-dialog__body {
        background-color black
        padding: 50px 0 50px;
    }
}
>>> .video-js .vjs-control-bar{
    bottom -6%
}
.detailImg {
    display block
    max-width 1200px
    max-height 700px
    width auto
    height auto
    margin-top 10px
    margin-bottom 30px
}
.detailImgVideoDiv{
    display block
    max-width 900px
    max-height 600px
    width auto
    height auto
    margin-top 10px
    margin-bottom 30px
    position relative
    .detailImgVideo{
        display block
        max-width 900px
        max-height 600px
        width auto
        height auto
        margin-top 10px
        margin-bottom 30px
    }
    .play{
        position absolute
        top 43%
        left 47%
        max-width 120px;
        max-height 60px
        width auto
        height auto
        cursor:pointer;
    }

}
.backDiv
    width 1100px
    text-align right
.backImg
    width 110px
    height 36px
    display inline-block
    cursor pointer
</style>

<style scoped>
.backbtn{
    margin-bottom: 3%;
    margin-top: 4%;
    margin-left: 56%;
    width: 6%;
    height: 18%;
    cursor: pointer;
}
</style>