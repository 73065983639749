import request from '@/utils/request'
import CONSTANT from '../../config/global.js'

/**
 * 查询官网经典案例一级数据
 * object{分页对象，相关属性current，pageSize，listMode}
 */
export function getGwList(data) {
  return request({
    url: CONSTANT.TwoHttp + '/external/officialCases/getList',
    method: 'post',
	data
  })
}
// 查询官网经典案例二级数据
// id
export function getGwTwoList(id){
	return request({
	  url: CONSTANT.TwoHttp + '/external/officialCases/secondGetList/?id='+id,
	  method: 'get',
	})
}
//查询首页经典二级数据
export function getGwSyList(params){
	return request({
	  url: CONSTANT.TwoHttp +'/external/officialCases/homePageCase',
	  method: 'get',
	  params
	})
}


